import { MultiSelect, Text } from '@mantine/core';
import { TFunction } from 'i18next';

import { usePrice } from '../../hooks';
import { IProdExtra, ISubExtraOptionAnswer, ValidLocales } from '../../typings';
import { getPriceText } from '../../utils';
import DownArrowIcon from './DownArrowIcon';

type Props = {
  isMobile: boolean;
  currency?: string;
  priceDecimalPoints?: number;
  locale?: ValidLocales;
  extra: IProdExtra;
  value: ISubExtraOptionAnswer<string>[];
  setValue: (value: ISubExtraOptionAnswer<string>[]) => void;
  t: TFunction;
};

const ExtraSelect = ({
  isMobile,
  currency,
  priceDecimalPoints,
  locale,
  extra,
  value,
  setValue,
  t,
}: Props) => {
  const { formatPrice } = usePrice({ locale, currency, priceDecimalPoints });

  const onChange = (options: string[]) => {
    setValue(
      options.map((option) => ({
        extraId: extra.id,
        extraOptionId: Number(option),
        value: null,
      })),
    );
  };

  return (
    <MultiSelect
      size="md"
      mb={20}
      rightSection={<DownArrowIcon size="13" />}
      rightSectionWidth={53}
      dropdownPosition={isMobile ? 'top' : 'flip'}
      {...(extra.max && { maxSelectedValues: extra.max })}
      label={
        <Text mb={15} size={16} weight={700} color="high-gray" transform="capitalize">
          {`${t('option-title', { option: extra.name })} ${
            extra.max
              ? `(${t('min-max', {
                  min: extra.min,
                  max: extra.max,
                })})`
              : ''
          } ${extra.min > 0 ? '*' : ''}`}
        </Text>
      }
      value={value?.map((option) => String(option.extraOptionId))}
      data={extra.options.map(
        (option) =>
          ({
            key: option.id,
            label: `${option.name} ${
              option.priceCents
                ? getPriceText({
                    t,
                    formatPrice,
                    priceCents: option.priceCents,
                  })
                : ''
            }`,
            value: String(option.id),
          } as any),
      )}
      onChange={onChange}
    />
  );
};

export default ExtraSelect;
