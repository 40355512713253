import { Box, Group } from '@mantine/core';

import { ICompany, IPage, ValidLocales } from '../../../typings';
import { ISpacing, ITheme, IThemeComponent } from '../../../typings/Theme';
import { formatSpacing } from '../../../utils';
import { ZammitComponent } from '../../ZammitComponent';

export interface DividedBannerProps {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  company: ICompany;
  locale?: ValidLocales;
  isMobile?: boolean;
  spacing: ISpacing;
  gap?: number;
  mobileSpacing: ISpacing;
  bgColor?: string;
  height?: number;
  mobileHeight?: number;
  componentChildren: IThemeComponent[];
}

export const DividedBanner = ({
  withinBuilder,
  theme,
  company,
  locale,
  isMobile,
  spacing,
  mobileSpacing,
  gap = 10,
  bgColor = '#fff',
  height,
  mobileHeight,
  componentChildren,
  ...props
}: DividedBannerProps) => {
  return (
    <Group
      grow={isMobile ? false : true}
      spacing={gap}
      sx={{
        ...formatSpacing(isMobile ? mobileSpacing : spacing),
      }}
      bg={bgColor}
      {...props}
    >
      {componentChildren.map((child) => (
        <Box key={child.id} w="100%">
          <ZammitComponent
            withinBuilder={withinBuilder}
            theme={theme}
            locale={locale}
            component={child}
            company={company}
            isMobile={isMobile}
            extraProps={{
              isDividedBanner: true,
              ...(height && { height }),
              ...(mobileHeight && { mobileHeight }),
            }}
          />
        </Box>
      ))}
    </Group>
  );
};
