import { TFunction } from 'i18next';

import { IProdExtra, ISubExtraOptionAnswer, ValidLocales } from '../../typings';
import ExtraCheckbox from './ExtraCheckbox';
import ExtraDate from './ExtraDate';
import ExtraSelect from './ExtraSelect';
import ExtraText from './ExtraText';
import ExtraTextarea from './ExtraTextarea';

type Props = {
  isMobile: boolean;
  currency?: string;
  priceDecimalPoints?: number;
  locale?: ValidLocales;
  extra: IProdExtra;
  value?: ISubExtraOptionAnswer<string> | ISubExtraOptionAnswer<string>[];
  setValue: (value: ISubExtraOptionAnswer<string> | ISubExtraOptionAnswer<string>[]) => void;
  t: TFunction;
};

const ProductExtra = ({
  isMobile,
  locale,
  currency,
  priceDecimalPoints,
  extra,
  value,
  setValue,
  t,
}: Props) => {
  switch (extra.type) {
    case 'dropdown':
      if (extra.viewType === 'multi_select')
        return (
          <ExtraSelect
            isMobile={isMobile}
            t={t}
            locale={locale}
            currency={currency}
            priceDecimalPoints={priceDecimalPoints}
            extra={extra}
            value={value as ISubExtraOptionAnswer<string>[]}
            setValue={setValue}
          />
        );
      else
        return (
          <ExtraCheckbox
            t={t}
            locale={locale}
            currency={currency}
            priceDecimalPoints={priceDecimalPoints}
            extra={extra}
            value={value as ISubExtraOptionAnswer<string>[]}
            setValue={setValue}
          />
        );

    case 'text':
      return (
        <ExtraText
          t={t}
          locale={locale}
          currency={currency}
          priceDecimalPoints={priceDecimalPoints}
          extra={extra}
          value={value as ISubExtraOptionAnswer<string>}
          setValue={setValue}
        />
      );

    case 'textarea':
      return (
        <ExtraTextarea
          t={t}
          locale={locale}
          currency={currency}
          priceDecimalPoints={priceDecimalPoints}
          extra={extra}
          value={value as ISubExtraOptionAnswer<string>}
          setValue={setValue}
        />
      );

    default:
      return (
        <ExtraDate
          t={t}
          locale={locale}
          extra={extra}
          value={value as ISubExtraOptionAnswer<string>}
          setValue={setValue}
        />
      );
  }
};

export default ProductExtra;
