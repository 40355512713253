import { TFunction } from 'i18next';

import { EXTRA_FILE_SIZE } from '../constants';
import {
  ICompany,
  IExtraOptionAnswer,
  IProdExtra,
  IProduct,
  IProductBasicDetails,
  IProductOption,
  IPurchase,
  IPurchaseProduct,
  IPurchaseProductExtras,
  ISubExtraOptionAnswer,
  IVariant,
} from '../typings';
import { isColor } from './global';

export const isSoldOut = (product: IProduct | IProductBasicDetails) =>
  (product.quantity <= 0 && product.isTracked) || product.status === 'sold_out';

export const getPriceRangeWithTemplate = ({
  priceRange,
  priceTemplate,
  formatPrice,
}: {
  priceRange: { minPrice: number; maxPrice: number };
  priceTemplate?: string;
  formatPrice: (value: number) => string;
}) => {
  const template = priceTemplate || 'min - max';

  return template
    .replace('min', formatPrice(priceRange.minPrice))
    .replace('max', formatPrice(priceRange.maxPrice));
};

export const hasVariantsOnSale = (variants: IVariant[]) => {
  return variants.some((variant) => variant.isOnSale);
};

export const getVariantsOriginalPriceRange = (variants: IVariant[]) => {
  let maxPrice = 0;
  let minPrice = Number.MAX_SAFE_INTEGER;

  for (const variant of variants) {
    if (variant.priceCents < minPrice) minPrice = variant.priceCents;
    if (variant.priceCents > maxPrice) maxPrice = variant.priceCents;
  }

  return {
    minPrice: minPrice / 100,
    maxPrice: maxPrice / 100,
  };
};

export const getVariantsOnSalePriceRange = (variants: IVariant[]) => {
  let maxPrice = 0;
  let minPrice = Number.MAX_SAFE_INTEGER;

  for (const variant of variants) {
    const variantSalePrice = variant.isOnSale ? variant.discountedPriceCents : variant.priceCents;

    if (variantSalePrice < minPrice) minPrice = variantSalePrice;

    if (variantSalePrice > maxPrice) maxPrice = variantSalePrice;
  }

  return {
    minPrice: minPrice / 100,
    maxPrice: maxPrice / 100,
  };
};

const getVariantsPriceRange = ({
  variants,
  isOriginalPriceEqual,
  isOnSalePriceEqual,
}: {
  variants: IVariant[];
  isOriginalPriceEqual: boolean;
  isOnSalePriceEqual: boolean;
}) => {
  const variantPrice = variants[0].priceCents / 100;
  const variantSalePrice = variants[0].discountedPriceCents / 100;

  if (isOriginalPriceEqual) {
    const onSaleRange = getVariantsOnSalePriceRange(variants);

    return {
      original: variantPrice,
      salePrice: onSaleRange,
    };
  } else if (isOnSalePriceEqual) {
    const originalRange = getVariantsOriginalPriceRange(variants);

    return {
      original: originalRange,
      salePrice: variantSalePrice,
    };
  } else {
    const isOnSale = hasVariantsOnSale(variants);

    const originalRange = getVariantsOriginalPriceRange(variants);
    const onSaleRange = isOnSale ? getVariantsOnSalePriceRange(variants) : null;

    return {
      original: originalRange,
      salePrice: onSaleRange,
    };
  }
};

export const areVariantsPricesEqual = (variants: IVariant[]) => {
  const prices = variants.map((variant) => variant.priceCents / 100);
  const onSalePrices = variants.map((variant) =>
    variant.isOnSale ? variant.discountedPriceCents / 100 : variant.priceCents / 100,
  );

  return {
    isOriginalPriceEqual: prices.every((price: number) => price === prices[0]),
    isOnSalePriceEqual: onSalePrices.every((price: number) => price === onSalePrices[0]),
  };
};

export const getProductPrice = (product: IProduct | IProductBasicDetails) => {
  if (product.defaultVariant) {
    return getVariantPrice({ variant: product.defaultVariant });
  } else {
    const { isOriginalPriceEqual, isOnSalePriceEqual } = areVariantsPricesEqual(product.variants);
    if (isOriginalPriceEqual && isOnSalePriceEqual) {
      return getVariantPrice({ variant: product.variants[0] });
    } else {
      return getVariantsPriceRange({
        variants: product.variants,
        isOriginalPriceEqual,
        isOnSalePriceEqual,
      });
    }
  }
};

export const getVariantPrice = ({
  variant,
  dimensions = 1,
}: {
  variant: IVariant;
  dimensions?: number;
}) => {
  const variantPrice = (variant.priceCents * dimensions) / 100;
  const variantSalePrice = (variant.discountedPriceCents * dimensions) / 100;

  return {
    original: variantPrice,
    salePrice: variant.isOnSale ? variantSalePrice : null,
  };
};

const getColorsCount = (colors: string[]) => {
  return colors.length > 4 ? '4+' : `${colors.length}`;
};

export const getProductColors = (productOptions: IProductOption[]) => {
  const colorsOption = productOptions?.find((option: IProductOption) =>
    isColor(option.option?.name),
  );

  return colorsOption && colorsOption.values.length > 0
    ? {
        values: colorsOption.values,
        count: getColorsCount(colorsOption.values),
      }
    : null;
};

export const getVariant = (variants: IVariant[], povs: string[]) => {
  // filter product variants to get the one matching all povs
  return variants.find((variant) => {
    const variantPOVs = variant.values.sort();
    const selectedPOVs = povs.sort();

    return (
      variantPOVs.length === selectedPOVs.length &&
      variantPOVs.every((element, index) => element === selectedPOVs[index])
    );
  });
};

export const isSize = (name: string) => ['size', 'sizes'].includes(name.toLowerCase());

export const getPriceText = ({
  t,
  formatPrice,
  priceCents,
  isPricePerLetter,
}: {
  t: TFunction;
  formatPrice: (price: number) => string;
  priceCents: number;
  isPricePerLetter?: boolean;
}) => {
  const price = `(+${formatPrice(priceCents / 100)})`;
  return isPricePerLetter ? `${price}/${t('letter')}` : price;
};

export const formatPurchaseProduct = ({
  productId,
  variant,
  quantity,
  pricingDetails,
  extras,
  addToCurrentQuantity,
}: {
  productId: number;
  variant: IVariant;
  quantity: number;
  pricingDetails?: IPurchaseProduct['pricingDetails'];
  extras?: IPurchaseProductExtras<string> | null;
  addToCurrentQuantity: boolean;
}) => {
  const extraOptionAnswers = extras ? Object.values(extras).flat(1) : null;

  return {
    productId,
    variantId: variant.id,
    quantity,
    ...(pricingDetails && { pricingDetails }),
    addToCurrentQuantity,
    ...(extraOptionAnswers && { extraOptionAnswers }),
  };
};

export const validateExtras = (
  productExtras: IProdExtra[],
  purchaseExtras: IPurchaseProductExtras<File | string> | null,
) => {
  const extraErrors: IProdExtra[] = [];

  productExtras.forEach((prodExtra) => {
    let isValid = true;

    const purchaseExtra =
      purchaseExtras && prodExtra.id in purchaseExtras ? purchaseExtras[prodExtra.id] : null;

    if (!purchaseExtra && !prodExtra.min && !prodExtra.isRequired) {
      isValid = true;
    } else if (purchaseExtra) {
      switch (prodExtra.type) {
        case 'dropdown':
          // eslint-disable-next-line no-case-declarations
          const extraValues = purchaseExtra as IExtraOptionAnswer[];
          isValid =
            (!prodExtra.min || extraValues.length >= prodExtra.min) &&
            (!prodExtra.max || extraValues.length <= prodExtra.max);
          break;
        case 'text':
          // eslint-disable-next-line no-case-declarations
          const textValue = (purchaseExtra as IExtraOptionAnswer).value;
          isValid =
            (!prodExtra.min || textValue!.length >= prodExtra.min) &&
            (!prodExtra.max || textValue!.length <= prodExtra.max);
          break;
        case 'textarea':
          // eslint-disable-next-line no-case-declarations
          const textareaValue = (purchaseExtra as IExtraOptionAnswer).value;
          isValid =
            (!prodExtra.min || textareaValue!.length >= prodExtra.min) &&
            (!prodExtra.max || textareaValue!.length <= prodExtra.max);
          break;
        case 'file':
          // eslint-disable-next-line no-case-declarations
          const file = (purchaseExtra as ISubExtraOptionAnswer<File>).value;
          isValid = !!file && file.size <= EXTRA_FILE_SIZE;
          break;
        default:
          isValid = true;
          break;
      }
    } else {
      isValid = false;
    }

    if (!isValid) extraErrors.push(prodExtra);
  });

  return extraErrors;
};

export const hasValidExtras = (
  productExtras: IProdExtra[],
  extras: IPurchaseProductExtras<File | string> | null,
) => productExtras.length === 0 || validateExtras(productExtras, extras).length === 0;

export const hasValidPricingDetails = (
  pricedBy: IProduct['pricedBy'],
  pricingDetails: IPurchaseProduct['pricingDetails'],
) => {
  switch (pricedBy) {
    case 'area':
      return pricingDetails?.width && pricingDetails.length;
    case 'weight':
      return pricingDetails?.weight;
    case 'product_length':
      return pricingDetails?.length;
    default:
      return true;
  }
};

export const hasPolicies = (company?: ICompany) => {
  return !!(
    company?.shippingPolicy?.trim().length || company?.returnAndRefundPolicy?.trim().length
  );
};

// MOT => company minimum order total
export const isPurchaseTotalLessThanMOT = ({
  company,
  purchase,
  selectedVariant,
  quantity,
}: {
  company?: ICompany;
  purchase?: IPurchase | null;
  selectedVariant: IVariant;
  quantity: number;
}) => {
  const minOrderTotal = company?.minimumOrderTotal || 0;

  const variantPrice =
    (selectedVariant.isOnSale ? selectedVariant.discountedPriceCents : selectedVariant.priceCents) /
    100;

  if (purchase) {
    return purchase.subtotal + variantPrice * quantity < minOrderTotal;
  } else {
    return variantPrice * quantity < minOrderTotal;
  }
};

export const getDefaultPricingDetails = (pricedBy: IProduct['pricedBy']) => {
  switch (pricedBy) {
    case 'area':
      return { length: 1, width: 1 };
    case 'product_length':
      return { length: 1 };
    case 'weight':
      return { weight: 1 };
    default:
      return null;
  }
};
