import {
  SegmentedControlProps as FormSegmentedControlProps,
  SegmentedControl as FormSegmentedControl,
} from "react-hook-form-mantine";
import {
  SegmentedControlProps as NormalSegmentedControlProps,
  SegmentedControl as NormalSegmentedControl,
} from "@mantine/core";
import { ITheme, IThemePage } from "shared";
import { Control } from "react-hook-form";

// Pro gamer move incoming
type FormProps = { control: Control<ITheme<IThemePage>> } & FormSegmentedControlProps<ITheme<IThemePage>>;
type NormalProps = { control?: undefined; name?: undefined } & NormalSegmentedControlProps;

type Props = FormProps | NormalProps;

const RadioButton = (props: Props) => {
  if (props.control) return <FormSegmentedControl {...props} />;
  else return <NormalSegmentedControl {...props} />;
};

export default RadioButton;
