import { Box, Group } from '@mantine/core';
import Image from 'next/future/image';

import {
  ICompany,
  Image as ImageType,
  IPage,
  ISpacing,
  ITheme,
  IThemeComponent,
  ValidLocales,
} from '../../../typings';
import { formatSpacing, getImageFitValue, getImageHeight, imageLoader } from '../../../utils';
import { ZammitComponent } from '../../ZammitComponent';

export interface HeroBannerProps {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  company: ICompany;
  locale?: ValidLocales;
  isMobile?: boolean;
  bgImage: ImageType;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  isDividedBanner?: boolean;
  componentChildren: IThemeComponent[];
  order: number;
  height?: number;
  mobileHeight?: number;
  imageScale?: 'Stretch' | 'Original Size' | 'Cover' | 'Scale to Fit';
  imagePosition?: string;
}

export const HeroBanner = ({
  withinBuilder,
  theme,
  company,
  locale,
  isMobile,
  bgImage,
  spacing,
  mobileSpacing,
  isDividedBanner,
  order,
  height = 400,
  mobileHeight,
  imageScale = 'Cover',
  imagePosition = 'center center',
  componentChildren,
}: HeroBannerProps) => {
  const imageFitValue = getImageFitValue(imageScale);
  const appliedHeight = getImageHeight(imageScale, height);
  const aspectRatio = bgImage.metadata
    ? bgImage.metadata.width / bgImage.metadata.height
    : undefined;

  const sizes = isDividedBanner ? '(max-width: 900px) 100vw, 50vw' : '100vw';

  return (
    <Box
      mx="auto"
      pos="relative"
      h={
        mobileHeight
          ? isMobile
            ? mobileHeight
            : height
          : //TODO: deprecate this later after migrating all to desktopHeight
          imageFitValue === 'contain'
          ? 'fit-content'
          : height
      }
      sx={{
        width: `calc(100% - ${isMobile ? mobileSpacing?.margin.right : spacing?.margin.right}px - ${
          isMobile ? mobileSpacing?.margin.left : spacing?.margin.left
        }px)`,
        aspectRatio: imageFitValue === 'contain' ? String(aspectRatio) : undefined,
        ...formatSpacing(isMobile ? mobileSpacing : spacing),
      }}
    >
      <Box
        w="100%"
        //TODO: deprecate appliedHeight later after migrating all to desktopHeight
        h={mobileHeight ? (isMobile ? mobileHeight : height) : appliedHeight}
        style={{
          position: 'absolute',
        }}
      >
        <Image
          src={bgImage.value[0]}
          loader={imageLoader({ urls: bgImage.value as string[], withinBuilder })}
          sizes={sizes}
          style={{ objectFit: imageFitValue, objectPosition: imagePosition }}
          fill
          alt={`banner image for ${company.name}`}
          priority={order <= 2}
        />
      </Box>
      <Group
        mx={50}
        h="100%"
        spacing={20}
        pos="relative"
        sx={{
          zIndex: 9,
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {componentChildren.map((child) => (
          <ZammitComponent
            key={child.id}
            theme={theme}
            locale={locale}
            component={child}
            company={company}
            isMobile={isMobile}
          />
        ))}
      </Group>
    </Box>
  );
};
