import { Text, TextInput } from '@mantine/core';
import { TFunction } from 'i18next';

import { usePrice } from '../../hooks';
import { IProdExtra, ISubExtraOptionAnswer, ValidLocales } from '../../typings';
import { getPriceText } from '../../utils';

type Props = {
  currency?: string;
  priceDecimalPoints?: number;
  locale?: ValidLocales;
  extra: IProdExtra;
  value?: ISubExtraOptionAnswer<string>;
  setValue: (value: ISubExtraOptionAnswer<string>) => void;
  t: TFunction;
};

const ExtraText = ({ locale, currency, priceDecimalPoints, extra, value, setValue, t }: Props) => {
  const { formatPrice } = usePrice({ locale, currency, priceDecimalPoints });

  const onChange = (event: any) => {
    setValue({
      extraId: extra.id,
      extraOptionId: extra.options[0].id,
      value: event.currentTarget.value,
    });
  };

  return (
    <TextInput
      size="md"
      mb={20}
      minLength={extra.min}
      maxLength={extra.max}
      label={
        <Text mb={15} size={16} weight={700} color="high-gray" transform="capitalize">
          {`${extra.name} ${
            extra.max
              ? `(${t('text-min-max', {
                  min: extra.min,
                  max: extra.max,
                })})`
              : ''
          } ${
            extra.options[0].priceCents
              ? getPriceText({
                  t,
                  formatPrice,
                  priceCents: extra.options[0].priceCents,
                  isPricePerLetter: extra.options[0].isPricePerLetter,
                })
              : ''
          } ${extra.min > 0 ? '*' : ''}`}
        </Text>
      }
      value={value?.value || ''}
      onChange={onChange}
    />
  );
};

export default ExtraText;
