import { ActionIcon, useMantineTheme } from '@mantine/core';
import { IconHeart, IconHeartFilled } from '@tabler/icons-react';

type Props = {
  id: number;
  withinCard?: boolean;
  isOnWishlist: (id: number) => boolean;
  toggleWishlist: (id: number) => void;
};

export const ProductWishlistIcon = ({ id, withinCard, isOnWishlist, toggleWishlist }: Props) => {
  const mantineTheme = useMantineTheme();

  return (
    <ActionIcon
      p={5}
      size={withinCard ? 24 : 42}
      maw={withinCard ? 24 : 42}
      variant="filled"
      color="btn-secondary"
      onClick={(e: any) => {
        e.preventDefault();
        toggleWishlist(id);
      }}
    >
      {isOnWishlist(id) ? (
        // we have to use style instead of color & fill due to bug in tabler, https://github.com/tabler/tabler-icons/issues/893
        <IconHeartFilled
          size={withinCard ? 20 : 36}
          style={{ color: mantineTheme.colors['btn-secondary-label'][0] }}
        />
      ) : (
        <IconHeart
          size={withinCard ? 20 : 36}
          color={mantineTheme.colors['btn-secondary-label'][0]}
        />
      )}
    </ActionIcon>
  );
};
