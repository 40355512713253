import {
  Box,
  Card as MantineCard,
  CardProps as MantineCardsProps,
  createStyles,
} from '@mantine/core';
import Image from 'next/future/image';
import Link from 'next/link';

import {
  ICompany,
  Image as ImageType,
  IPage,
  ISpacing,
  ITheme,
  IThemeComponent,
  ValidLocales,
} from '../../../typings';
import { formatSpacing, getImageFitValue, getImageHeight, imageLoader } from '../../../utils';
import { ZammitComponent } from '../../ZammitComponent';

const useStyles = createStyles(() => ({
  image: {
    transition: 'all .4s ease-in-out',

    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
}));

export interface CardProps extends MantineCardsProps {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  company: ICompany;
  locale?: ValidLocales;
  isMobile?: boolean;
  image: ImageType;
  bgColor: string;
  title: string;
  desc: string;
  imageScale: string;
  height: number;
  textAlign: 'start' | 'center' | 'end';
  url?: string;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  columns?: number;
  mobileColumns?: number;
  enableImageHoverEffect?: boolean;
  componentChildren: IThemeComponent[];
  order: number;
}

type CardContentProps = Omit<CardProps, 'bgColor' | 'spacing' | 'mobileSpacing'>;

const CardContent = ({
  withinBuilder,
  theme,
  company,
  locale,
  isMobile,
  image,
  title,
  desc,
  imageScale,
  textAlign,
  height = 150,
  columns,
  mobileColumns,
  enableImageHoverEffect,
  componentChildren,
  order,
}: CardContentProps) => {
  const { cx, classes } = useStyles();

  const noOfColumns = columns ? columns : 1;

  const noOfMobileColumns = mobileColumns ? mobileColumns : noOfColumns;

  const sizes = `(max-width: 900px) ${100 / noOfMobileColumns}vw, ${100 / noOfColumns}vw`;

  const imageFitValue = getImageFitValue(imageScale);
  const appliedHeight = getImageHeight(imageScale, height);

  const aspectRatio = image.metadata ? image.metadata.width / image.metadata.height : undefined;

  return (
    <>
      {image.value.length !== 0 && image.value[0] && (
        <Box
          h={imageFitValue === 'contain' ? 'fit-content' : height}
          sx={{ aspectRatio: imageFitValue === 'contain' ? String(aspectRatio) : undefined }}
          style={{
            position: 'relative',
          }}
        >
          <Box
            h={appliedHeight}
            w="100%"
            style={{
              paddingBottom: 20,
              position: 'absolute',
            }}
          >
            <Image
              src={image.value[0]}
              loader={imageLoader({ urls: image.value as string[], withinBuilder })}
              sizes={sizes}
              alt={title}
              style={{ objectFit: imageFitValue }}
              className={cx({ [classes.image]: enableImageHoverEffect })}
              fill
              priority={order <= 2}
            />
          </Box>
        </Box>
      )}
      <Box mb={20} px={5}>
        <Box sx={{ textAlign }} p={1} dangerouslySetInnerHTML={{ __html: title }} w="100%" />
        <Box sx={{ textAlign }} p={1} dangerouslySetInnerHTML={{ __html: desc }} w="100%" />
      </Box>
      {componentChildren.map((child) => (
        <ZammitComponent
          key={child.id}
          theme={theme}
          locale={locale}
          component={child}
          company={company}
          isMobile={isMobile}
        />
      ))}
    </>
  );
};

export const Card = ({ isMobile, url, bgColor, spacing, mobileSpacing, ...props }: CardProps) => {
  return url ? (
    <Link href={url} passHref>
      <MantineCard
        component="a"
        style={{ backgroundColor: bgColor, ...formatSpacing(isMobile ? mobileSpacing : spacing) }}
      >
        <CardContent isMobile={isMobile} {...props} />
      </MantineCard>
    </Link>
  ) : (
    <MantineCard
      style={{ backgroundColor: bgColor, ...formatSpacing(isMobile ? mobileSpacing : spacing) }}
    >
      <CardContent isMobile={isMobile} {...props} />
    </MantineCard>
  );
};
